import React from 'react';

import './Button.css';

import { Link } from 'react-router-dom';



const Button = ({ title, link = '', download = false, downloadName = '' }) => {

 return (

  download ? (

   <a href={link} download={downloadName} className="btn">{title}</a>

  ) : (

   <Link to={link} className="btn">{title}</Link>

  )

 );

};



export default Button