import { images } from '.'; 
 
const portfolioContent = [
    {
        id: 1,
        image: images.portfolio1,
        title: 'Artificial Intelligence',
        category: 'Project Management',
        tag: 'Project Management',
        link: '#'
    },
    {
        id: 2,
        image: images.portfolio2,
        title: 'Application Upgrade',
        category: 'Project Management',
        tag: 'Project Management',
        link: '#'
    },
    {
        id: 3,
        image: images.portfolio3,
        title: 'Information Security',
        category: 'Project Mangement',
        tag: 'Project Management',
        link: '#',
    },
    {
        id: 4,
        image: images.portfolio4,
        title: 'Virtual Assitance & NLU',
        category: 'Business Analysis',
        tag: 'Business Analysis',
        link: '#'
    },
    {
        id: 5,
        image: images.portfolio5,
        title: 'IVR System',
        category: 'Business Analysis',
        tag: 'Business Analysis',
        link: '#'
    },
    {
        id: 6,
        image: images.portfolio6,
        title: 'Agile Implementation',
        category: 'Agile Coaching',
        tag: 'Agile Coaching',
        link: '#'
    },
    {
        id: 7,
        image: images.portfolio7,
        title: 'Organizational Change',
        category: 'Change Management',
        tag: 'Change Management',
        link: '#'
    },
    {
        id: 8,
        image: images.portfolio8,
        title: 'Software Build',
        category: 'Project Management',
        tag: 'Project Management',
        link: '#'
    },
    {
        id: 9,
        image: images.portfolio9,
        title: 'Training',
        category: 'Change Management',
        tag: 'Change Management',
        link: '#'
    },
];

export default portfolioContent;

