import { images } from '.';


const testimonials = [ 
    { 
        id: 1,
        
        name: 'Brad Robeson',
        title: 'Senior Leader, Program Management',
        content: 'Busola is a very experienced and highly capable Project Manager. In my experience working with Busola, shes demonstrated exceptional competencies in her role related to project oversight and delivery on time and within budget. In addition in my experience working with Busola is that she excels at developing strong relationships with her customers that provided a balance of value to both the client and to the company. Certainly an asset to any organizationa  exerienced atainly an asset to any organization.',
    },
    {
        id: 2,
      
        name: 'Simon Gingras',
        title: 'Director of Information Technology, Canada Bar Association',
        content: 'It has been a pleasure working with Busola Akinwumi, whose expertise as a Project Manager at CBA has been nothing short of outstanding. She possesses an exceptional ability to manage both people and processes, adeptly coordinating cross-functional teams to achieve project milestones. Her excellent communication skills and collaborative spirit have created an environment where each team member felt empowered and motivated.',
    },
    
               
    
];

export default testimonials;