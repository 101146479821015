export const skills = [
    {
        id: 1,
        title: 'ADO',
        progress: '95%',
    },
    {
        id: 2,
        title: 'JIRA',
        progress: '95%',
    },
    {
        id: 3,
        title: 'Microsoft Project',
        progress: '95%',  
    },
    {
        id: 4,
        title: 'PowerPoint',
        progress: '97%',
    },
    {
        id: 5,
        title: 'Excel',
        progress: '90%',
    },
    {
        id: 6,
        title: 'SharePoint',
        progress: '95%',
    },

];

export const education = [

    {
        id: 1, 
        duration: '2002 - 2006',
        title: 'BSc. Computer Science',
        content: 'Software development & engineering, networks, hardware, programming, System Architecture, Security Architecture, Databases etc.',
    },
    {
        id: 2,
        duration: '2009 - 2011',
        title: 'Msc. Information Systems',
        content: 'Optimizing Technology Solutions for Complex business challenges, Artificial Intelligence, Data Analytics, Systems Intgeration, IT infrastructure, Software Applications etc.',
    },
    {
        id: 3,
        duration: '2016 - 2017',
        title: 'Project Management Diploma',
        content: 'Agile, waterfall or hybrid approach to managment complex or simple projects. Stakeholder, communication, risk, procurement, cost, schedule, resource & Integration management.',
    },
 
];


export const experience = [

    {
        id: 1,
        duration: '2018 - Ongoing',
        title: 'Program Manager',
        content: 'Clients: Microsoft US, Nuance, CBA, CRA, Canada Life, Bell Canada. Projects: AI, IVR, Security Operations, Application Upgrade, Unified Communications, IPVPN, Projects etc.',
        
    },
    {
        id: 2,
        duration: '2017 - 2018',
        title: 'Project Manager & Business Analyst',
        content: 'Assiniboine Credit Union: IVR Implementation, Loan Management System upgrade, PMO continuous improvement.',
    },
    {
        id: 3,
        duration: '2014-2016',
        title: 'Senior Associate',
        content: 'KPMG Professional Services: Client- Financial Services, Project Management, Business Analysis, System implementation and change Management services.',
    },
 
];