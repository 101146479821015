import React from 'react';
import './BlogSingle.css';

import { BsCalendar } from 'react-icons/bs'; 
import { GoQuote } from 'react-icons/go';

import { useLocation } from "react-router-dom";
 
    
 
const BlogSingle = () => { 

  const location = useLocation();
  const blog = location.state;  

  const paragraphs = blog.paragraph;

  return (   
    <>

      {/* Blog Single Section */}
      <section className='blog-single'>

        <div className='blog-container'> 

          {/* Blog Information */}
          <div className='blog-info' key={blog.id}>

            {/* Blog Image Container */}
            <div className='image'>
              <img src={blog.Image} alt='Blog' /> {/* Blog Image */}
            </div>

            {/* Blog Content Container */}
            <div className='content'> 

              {/* Blog Details */}
              <div className='details'>
                {/* Blog category */}
                <div className='category'>{blog.category}</div>
                <h3><BsCalendar className='icon' /><span>{blog.date} {blog.month}, {blog.year}</span></h3>
              </div>

              {/* Blog Heading */}
              <h3 className='main-heading'>{blog.title}</h3>

              {/* Blog paragraphs */}
              <p>{paragraphs.para1}</p>  
              <p>{paragraphs.para2}</p> 
              <p>{paragraphs.para3}</p> 

              <div className='important'>
                <GoQuote className='icon' />
                <div className='box-container'>
                  {/* Quoted text */}
                  {blog.important}
                </div>
              </div>

              <div className='blog-points'>
                <h4 className='sub-heading'>{blog.subSection1.subHeading}</h4>
                <p>{blog.subSection1.details}</p>



                <ul>
                  {
                    (blog.subSection1.points).map((point) => {
                      return <li key={point.id}><strong>{point.title}</strong>{point.details}</li>
                    })
                  }
                </ul>
              </div>

              {/* Blog points */}
              <div className='blog-points'>
                <h4 className='sub-heading'>{blog.subSection2.subHeading}</h4>
                <p>{blog.subSection2.details}</p>
                <ul>
                  {
                    (blog.subSection2.points).map((point) => {
                      return <li key={point.id}><strong>{point.title}</strong>{point.details}</li>
                    })
                  }
                </ul>
              </div> 

              <div className='blog-points'>
                <h4 className='sub-heading'>{blog.subSection3.subHeading}</h4>
                <p>{blog.subSection3.details}</p>
                <ul>
                  {
                    (blog.subSection3.points).map((point) => {
                      return <li key={point.id}><strong>{point.title}</strong>{point.details}</li>
                    })
                  }
                </ul>
              </div>

              <div className='blog-points'>
                <h4 className='sub-heading'>{blog.subSection4.subHeading}</h4>


                <p>{blog.subSection4.details}</p>
                <ul>
                  {
                    (blog.subSection4.points).map((point) => {
                      return <li key={point.id}><strong>{point.title}</strong>{point.details}</li>
                    })
                  }
                </ul>
              </div>

              <div className='blog-points'>
                <h4 className='sub-heading'>{blog.subSection5.subHeading}</h4>

                <p>{blog.subSection5.details}</p>
                <ul>
                  {
                    (blog.subSection5.points).map((point) => {
                      return <li key={point.id}><strong>{point.title}</strong>{point.details}</li>
                    })
                  }
                </ul>
              </div>

              <div className='blog-points'>
                <h4 className='sub-heading'>{blog.subSection6.subHeading}</h4>
                <p>{blog.subSection6.details}</p>                
                <ul>
                  {
                    (blog.subSection6.points).map((point) => {
                      return <li key={point.id}><strong>{point.title}</strong>{point.details}</li>
                    })
                  }
                </ul>
              </div>

              <div className='blog-points'>
                <h4 className='sub-heading'>{blog.subSection7.subHeading}</h4>
                <p>{blog.subSection7.details}</p>                
                <ul>
                  {
                    (blog.subSection7.points).map((point) => {
                      return <li key={point.id}><strong>{point.title}</strong>{point.details}</li>
                    })
                  }
                </ul>
              </div>

              <div className='blog-points'>
                <h4 className='sub-heading'>{blog.subSection8.subHeading}</h4>
                <p>{blog.subSection8.details}</p>                
                <ul>
                  {
                    (blog.subSection8.points).map((point) => {
                      return <li key={point.id}><strong>{point.title}</strong>{point.details}</li>
                    })
                  }
                </ul>
              </div>

            </div> 
          </div>

        </div>

      </section>

    </>

  )
}

export default BlogSingle;