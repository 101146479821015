
import { FaPaintBrush, FaFileVideo, FaSearchDollar } from 'react-icons/fa';
import { BsCodeSlash } from 'react-icons/bs';
import { MdPhotoCamera } from 'react-icons/md';
import { ImBullhorn } from 'react-icons/im';

const services = [
    {
        id: 1,
        icon: <BsCodeSlash />,
        title: 'Project Management',
        content:'I provide exceptional Program & Project Management Services. delivering solutions that are strategically aligned with business goals and drive measureable success with a focus on cross-functional collaboration and agile methods.',
    },
    {
        id: 2,
        icon: <FaPaintBrush />,
        title: 'Business Analysis',
        content: 'I provide exceptional Business Analysis expertise, focusing on translating complex business needs into actionable insights and solutions. I perform thorough stakeholder engagement to ensure that requirements are clearly defined.',
    },
    {
        id: 3,
        icon: <MdPhotoCamera />,
        title: 'Scrum Implementation',
        content: 'As a certified Scrum Educator, I provide Agile and Scrum coaching to empower teams with the skills and mindset needed for successful agile transformations.  I guide teams in adopting agile practices to maximize efficiency and adaptability.',
        
    },
    {
        id: 4,
        icon: <ImBullhorn />,
        title: 'Coaching & Training',
        content: ' I empower professionals to enhance their project delivery skills, instilling best practices in planning, execution, and risk management. My coaching approach focuses on building leadership capabilities, and fostering clear communication to ensure project success.',
    },
    {
        id: 5,
        icon: <FaFileVideo />,
        title: 'Change Management',
        content: 'I drive successful transitions by guiding teams and stakeholders through structured change initiatives. My approach integrates people- focused strategies with process optimization to minimize resistance and promote adoption of new systems , process and technolog.',
    },
    {
        id: 6,
        icon: <FaSearchDollar />,
        title: 'Process Improvement',
        content: 'I bring robust approach to process improvement, focusing on streamlining workflows to enhance efficiency and productivity, while championing a culture of continuous improvement, fostering adaptability and proactive problem solving with teams.',
    },

];

export default services;