import React, {useState, useEffect} from 'react';
import './Sidebar.css';

import { FaBars, FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaRegUser, FaBriefcase, FaBlog } from 'react-icons/fa';
import { GoHome } from 'react-icons/go';
import { MdContacts } from 'react-icons/md';
import { AiOutlineComment } from 'react-icons/ai';
import { FiList } from 'react-icons/fi';
import profilePic from '../../assets/Profile-Pic.PNG';


import { Link } from 'react-router-dom';

 

const Sidebar = () => {

  const [click, setClick] = useState(false);

  const handleClick = (e) => {
    setClick(!click);
    e.preventDefault();
  }

  const closeMenuBar = () => setClick(false);

  /* Manage On Scroll Functionality */
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  });

  const handleScroll = (e) => {

    /* Close Menubar On Scroll */
    const sidebar = document.querySelector('.sidebar');
    sidebar.classList.remove('active');
    setClick(false);
  }; 


  return (
    <div className={click ? 'sidebar active' : 'sidebar'}>

      <div className='logo'>
        <h2> <Link to='/'>Busola</Link> </h2>
        <div className='menu-btn' id='menu' onClick={handleClick}><FaBars /></div>
      </div>  

      <img src={profilePic} alt="Profile-Pic" />

 
      <ul className='navbar'>
        <li><Link to='/' onClick={closeMenuBar}><GoHome className='icon' /><span>home</span></Link></li>
        <li><Link to='/About' onClick={closeMenuBar}><FaRegUser className='icon' /><span>about</span></Link></li>
        <li><Link to='/Services' onClick={closeMenuBar}><FiList className='icon' /><span>service</span></Link></li>
        <li><Link to='/Portfolio' onClick={closeMenuBar}><FaBriefcase className='icon' /><span>portfolio</span></Link></li>
        <li><Link to='/Testimonials' onClick={closeMenuBar}><AiOutlineComment className='icon' /><span>testimonials</span></Link></li>
        <li><Link to='/Blogs' onClick={closeMenuBar}><FaBlog className='icon' /><span>blog</span></Link></li>
        <li><Link to='/Contact' onClick={closeMenuBar}><MdContacts className='icon' /><span>contact</span></Link></li>
      </ul>
 
      <div className='social'>
        
        <a href='https://www.linkedin.com/in/busola-akinwumi'><FaLinkedinIn /></a>
      </div>

    </div>
  )
}

export default Sidebar;