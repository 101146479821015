import { AiTwotonePhone } from 'react-icons/ai'; 
import { FaEnvelope } from 'react-icons/fa';
import { BsLinkedin, BsGithub } from 'react-icons/bs';


const contacts = [
    {
        id: 1,
        icon: <AiTwotonePhone />,
        title: 'Phone:',
        content: ['+1-613-875-3377', '+1-613-875-5587'],
    },
    {
        id: 2,
        icon: <FaEnvelope />,
        title: 'email:',
        content: ['busola.a@bemicconsulting.com', 'busolaakinwumi@gmail.com'],
    },
    {
        id: 3,
        icon: <BsLinkedin />,
        title: 'linkedin:',
        content: ['https://www.linkedin.com/in/busola-akinwumi'],
    },
    {
        id: 4,
        icon: <BsGithub />,
        title: 'Website:',
        content: ['https://busolaakinwumi.com/'],
    },
 
];

export default contacts;