import React from 'react';
import './Home.css';

import { Button } from '../../components';

import Typed from 'react-typed';

const Home = () => {

  const fields = [
    'Project Manager',
    'Agile Coach',
    'Business Analyst',
    'Organizational Change Manager'
  ];

  return ( 

    <section className='home' id='home'> 

      <div className='box-container'>
  
        <div className='content'>
          <h1>hello! I'm Busola</h1>
          <h4><Typed strings={fields} typeSpeed={60} backSpeed={50} loop /></h4>
          <p>With over 15+ years of experience providing professional services accross various organizations.</p>
          <Button link={'/Contact'} title={'contact me'} />
        </div>

      </div> 

    </section>

  )
}

export default Home